<template>
  <div class="privacyBox">
    <h3>隐私条款</h3>
    <div class="line"></div>
    <ul class="ulOne">
      <li>
        感谢您对香港红狮集团的信赖和支持，我们非常重视客户的个人信息，将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全。鉴此，香港红狮集团(或简称“我们”/“我司”)制定本《隐私政策》(下称“本政策/本隐私政策”)并提醒您:
      </li>
      <li>
        本政策适用于香港提供的产品或服务。如我们及关联公司产品或服务中使用了香港红狮集团提供的产品或服务但未设独立法律声明及隐私权政策的，则本政策同样适用于该部分产品或服务。
      </li>
      <li>
        本政策与您使用我们的服务关系紧密，在使用我司产品和服务前，请您务必仔细阅读本政策，在您确认充分理解并同意本政策后使用相关产品和服务。一旦您开始使用我们的各项产品或服务，即表示您已充分理解并同意本政策，并同意我们按照本政策的约定收集、使用、储存、分享和保护您的相关信息。
      </li>
      <li>
        如对本政策或相关事宜有任何问题，请通过香港红狮集团客服与我们联系。
      </li>
    </ul>
    <ul class="ulTwo">
      <li>本隐私政策将帮助您了解以下内容:</li>
      <li>1、 我们如何收集您的个人信息</li>
      <li>2、 我们如何和使用您的个人信息</li>
      <li>3、 我们如何使用Cookie和同类技术</li>
      <li>4、 我们如何共享、转让、公开披露您的个人信息</li>
      <li>5、 我们如何保护您的个人信息</li>
      <li>6、 我们如何存储信息</li>
      <li>7、 您管理自己信息的权利</li>
      <li>8、 我们如何保护未成年人的信息</li>
      <li>9、 您的个人信息如何在全球范围转移</li>
      <li>10、我们可能向您发送的信息</li>
      <li>11、您分享的信息</li>
      <li>12、本政策如何更新</li>
      <li>13、本政策适用范围</li>
      <li>14、如何联系我们</li>
      <li>
        我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息:权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。
      </li>
    </ul>
    <h4 style="margin: bottom 0.5rem;">一、我们如何收集您的个人信息</h4>
    <ul class="ulThree">
      <li>
        1. 为了向您提供服务，维护香港红狮集团产品及服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用产品及服务时主动提供、授权提供或基于您的要求提供的信息，以及基于您使用香港红狮集团服务时产生的信息:
      </li>
      <li>
        (1) <span>*手机号码</span> ：当您使用手机号码及验证码注册香港红狮集团时，我们会收集您的<b>手机号码</b>。手机号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。
      </li>
      <li>
        (2) <span>*用户地址</span> ：为保证您能正常领取和使用有关行情权益及服务，我们会对您提供的<b>香港红狮集团号、IP地址、第三方账号和头像、昵称等用以进行地域或账户</b>有效性识别。如您不同意上述内容，可能会影响地域或账户的有效性判断，进而影响到您领取和使用有关行情权益及服务。
      </li>
      <li>
        (3) <span>*<b>用户姓名</b></span> ：确保用户的资金操作是本人。
      </li>
      <li>
        (4) <span>*相机/相册</span> ：当您通过香港红狮集团接入完善账户功能时，香港红狮集团会使用设备的<b>摄像头权限</b>，拒绝使用摄像头权限，完善账户流程将无法正常完成。根据法律要求，在您通过香港红狮集团申请开户时，必须提交<b>身份证正反面照片、银行卡照片</b>等信息。若您不同意上述内容，您将无法使用开户功能，但不影响您使用香港红狮集团的其他服务。
      </li>
      <li>
        (5)当您使用香港红狮集团时，为保障您正常使用我们的服务，且为向您提供更契合您需求的页面展示和推荐个性化内容、进行用户画像、了解产品适配性、识别账号异常状态，以及改进和优化我们的服务体验及保障您的账号安全的需要，我们会收集关于您使用的服务及使用方式的信息并将这些信息进行关联，这些信息包括:
      </li>
      <li>
        (i)日志信息:当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的<b>登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注及分享。我们会获取您设备的存储权限，用于保存前述日志信息</b>。
      </li>
      <li>
        (ii)设备信息:我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息(例如<b>IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、唯一设备标识符、软硬件特征信息)、设备所在位置相关信息(例如IP地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息</b>)
      </li>
    </ul>
    <ul class="ulFour">
      <p>
        2.另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意:
      </p>
      <li>
        (1)与国家安全、国防安全等国家利益直接相关的;
      </li>
      <li>
        (2)与公共安全、公共卫生、公众知情等重大公共利益直接相关的;
      </li>
      <li>
        (3)与犯罪侦查、起诉、审判和判决执行等直接相关的;
      </li>
      <li>
        (4)出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的;
      </li>
      <li>
        (5)所收集的个人信息是您自行向社会公众公开的;
      </li>
      <li>
        (6)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道;
      </li>
      <li>
        (7)根据您要求签订和履行合同所必需的;
      </li>
      <li>
        (8)用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障;
      </li>
      <li>
        (9)为开展合法的新闻报道所必需的;
      </li>
      <li>
        (10)出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的;
      </li>
      <li>
        (11)法律法规规定的其他情形。
      </li>
    </ul>
    <ul class="ulFour">
      <p>
        3.请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        4.我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。我们将在符合相关法律规定，并依据与关联方或者第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        5.以上由您提供的信息中，可能包含您的个人敏感信息，例如<b>银行账号、交易记录、虚拟财产信息、系统账号、邮箱地址及其有关的密码、电话号码、网页浏览记录、位置信息</b>。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本隐私政策所述的目的和方式来处理。
      </p>
    </ul>
    <h4 style="margin: bottom 0.5rem;">二、我们将如何使用收集的信息</h4>
    <ul class="ulTwo">
      <li>
        基于国家相关法律法规的规定，以及为向您提供服务及提升服务质量、向您提供安全、顺畅、高效和个性化的体验的目的，我们将在严格遵守法律法规的规定、本隐私政策及其他与您的约定的前提下，将收集的信息用于以下用途。
      </li>
      <li>
        1、向您提供各项相关服务;
      </li>
      <li>
        2、使我们了解您如何接入和使用相关服务，以满足您的个性化需求;
      </li>
      <li>
        3、开发和服务优化。例如，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务;
      </li>
      <li>
        4、向您发送营销信息推广香港红狮集团服务或第三方商品和服务，推荐您感兴趣的信息和资讯以及发出与香港红狮集团服务有关的通知;
      </li>
      <li>
        5、评估、改善我们的推广效果等;
      </li>
      <li>
        6、管理软件。例如，进行软件认证、软件升级等;
      </li>
      <li>
        7、邀请您参与有关我们服务的调查;
      </li>
      <li>
        8、预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，保护您、其他用户或公众以及我们或我们关联公司的合法权益，我们会使用或整合您的个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      </li>
      <li>
        9、为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、崩溃数据、总体使用情况、性能数据以及应用程序的来源，但我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合;
      </li>
      <li>
        10、经您授权的其他用途。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。
      </li>
    </ul>
    <h4>三、我们如何使用Cookie和同类技术</h4>
    <ul class="ulFour">
      <p>
        1、Cookie
      </p>
      <li>
        为使您获得更轻松的访问体验，您使用香港红狮集团产品或服务时，我们可能会通过采用各种技术收集和存储您访问香港红狮集团服务的相关数据，在您访问或再次访问香港红狮集团服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务，包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储(统称“Cookie”)。我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie 的功能。但如果您这么做，在某些情况下您可能无法使用依赖于cookies的香港红狮集团服务的部分功能，则需要在每一次访问我们的网站时亲自更改用户设置。
      </li>
    </ul>
    <ul class="ulFour">
      <p>
        2、网站信标和像素标签
      </p>
      <li>
        除 Cookie 外，我们还可能在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。
      </li>
    </ul>
    <ul class="ulFour">
      <p>
        3、Do Not Track(请勿追踪)
      </p>
      <li>
        很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们会尊重您的选择。
      </li>
    </ul>
    <h4>四、我们如何共享、转让、公开披露您的个人信息</h4>
    <ul class="ulTwo">
      <h5>1、共享</h5>
      <li>
        我们不会与香港红狮集团服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外:
      </li>
      <li>
        (1)在获取您同意的情况下共享:获得您的明确同意后，我们会与其他方共享您的个人信息。
      </li>
      <li>
        (2)在法定情形下的共享:我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </li>
      <li>
        (3)只有透露您的资料(如您的IDFA、网络身份标识符、IP地址、IMEI、MAC地址等相关信息)，才能提供您所要求的第三方产品和服务，或者用于香港红狮集团进行数据分析或研究、改善我们的产品和服务。目前我们接入的第三方SDK服务主要包括以下几种类型:
      </li>
      <li>
        ● 提供IM消息通信功能;
      </li>
      <li>
        ● 进行用户行为数据上报分析;
      </li>
      <li>
        ● 提供支付服务;
      </li>
      <li>
        ● 进行程序crash信息上报;
      </li>
      <li>
        ● 进行性能统计;
      </li>
      <li>
        ● 提供浏览器服务;
      </li>
      <li>
        ● 用于广告统计及监控;
      </li>
      <li>
        ● 用于便于您分享香港红狮集团内容至第三方平台;
      </li>
      <li>
        该等接入第三方服务由相关方负责运营，须受第三方自己的服务条款及信息保护声明(而非本《隐私政策》)约束
      </li>
      <li>
        (4)如您被他人投诉或您投诉他人，我们会将您的身份信息等投诉相关信息提供给相关监管机构，用于解决投诉纠纷，但法律法规明确禁止提供的除外。
      </li>
      <li>
        (5)为了遵守法律、执行或适用我们服务的使用条件和其他协议，或者为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。
      </li>
      <li>
        (6)与授权合作伙伴共享:仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您参与我们提供的奖励活动时，我们必须与合作伙伴共享您的个人信息才能安排发放奖励，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
      </li>
      <li>
        (7)依据本软件《服务协议》与您的相关约定向第三方共享。
      </li>
      <li>
        (8)您与我们关于信息共享的其他约定。
      </li>
      <li>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </li>
      <h5>2、转让</h5>
      <li>
        除以下情形外，我们不会主动转让您的个人信息至:<br/>
        (1)相关操作在事前已征得您的明示同意的;<br/>
        (2)在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意;<br/>
        (3)其他因根据法律法规的规定、有权机关的要求需要转让您的个人信息的。<br/>
      </li>
      <h5>3、公开披露</h5>
      <li>
        我们仅会在以下情况下，公开披露您的个人信息:<br/>
        (1)获得您明确同意后;<br/>
        (2)基于法律的公开披露:在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息;<br/>  
        (3)如果我们确定您出现违反法律法规或严重违反香港红狮集团相关协议规则的情况，或为保护香港红狮集团及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或香港红狮集团相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及香港红狮集团已对您采取的措施。
      </li>
      <h5>4、共享、转让、公开披露个人信息时事先征得授权同意的例外</h5>
      <li>
        (1)与国家安全、国防安全有关的;<br/> 
        (2)与公共安全、公共卫生、重大公共利益有关的;<br/> 
        (3)与犯罪侦查、起诉、审判和判决执行等有关的;<br/> 
        (4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;<br/> 
        (5)您自行向社会公众公开的个人信息;<br/> 
        (6)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/> 
        (7)请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </li>
    </ul>
    <h4>五、我们如何保护您的个人信息</h4>
    <ul class="ulFour">
      <p>
        我们会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，我们有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他香港红狮集团用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        我们会建立应急处理预案，若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将尽力确保您的信息的安全性。
      </p>
    </ul>
    <h4>六、我们如何存储信息</h4>
    <ul class="ulFour">
      <p>
        1.我们会按照相关法律法规规定，收集的您的个人信息存储于红狮服务器，并依法对这些信息进行严格保密。如部分情形下需要向境外机构传输境内收集的相关用户个人信息的，我们会按照法律、行政法规和相关监管部门的规定执行，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。交易商将尽一切所能确保其收集所得的客户个人资料被妥善地保存在储存系统，个人资料将留存在储存系统中5年，超过存储期限后，用户未声明销毁的情况下，公司将自动延长5年存储期限；若用户声明销毁，公司将立即按照用户声明将个人资料进行销毁处理。只有获授权的雇员或向交易商提供服务的公司可优先地查询有关资料，但鉴于互联网之公开及全球性质，若客户经由交易商网页浏览至他人运作的网页或因客户自身操作不当泄露的信息，客户的个人资料均不属交易商的个人资料隐私条款保障范围内。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        我们会建立应急处理预案，若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将尽力确保您的信息的安全性。
      </p>
      <p>
        2.一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。但在下 列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间:
      </p>
      <li>
        (1)为遵守适用的法律法规等有关规定;<br/>
        (2)为遵守法院判决、裁定或其他法律程序的规定;<br/>
        (3)为遵守相关政府机关或法定授权组织的要求;<br/>
        (4)为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。
      </li>
    </ul>
    <h4>七、您管理自己信息的权利</h4>
    <ul class="ulFour">
      <p>
        1.在您使用香港红狮集团的服务期间，为了您可以更加便捷地访问、更正、删除您的个人信息，同时保障您撤回对个人信息使用的同意及注销账户的权利，我们在产品设计中为您提供了包括但不限于以下操作设置:
      </p>
      <li>
        (1)访问您的账户信息，如果您希望访问或编辑您的账户中的个人资料信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问香港红狮集团app-【我的】-【我的资料】执行此类操作。<br/>
        (2)修改个人信息，您可以通过访问香港红狮集团app-【我的】-【我的资料】执行此类操作;
      </li>
    </ul>
    <ul class="ulFour">
      <p>
        2.如您发现我们采集、使用您个人信息的行为，违反了法律、行政法规规定或违反了与您的约定，您可通过香港红狮集团客服联系我们，要求删除该违反行为下采集的您的个人信息。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        3.如您发现我们采集、储存的您的个人信息有错误的，您可以通过香港红狮集团客服联系并要求我们及时更正。
      </p>
    </ul>
    <ul class="ulFour">
      <p>
        4.客户可不时查询及更改由交易商所保存的有关个人资料；客户如需删除个人信息、注销账户或撤回已同意的授权，可联系客服核实处理，前述指示将在10个工作日内完成。客户的账号注销完成后，公司将立即销毁用户的个人信息。
      </p>
    </ul>
    <h4 style="margin: bottom 0.5rem;">八、我们如何保护未成年人的信息</h4>
    <ul class="ulTwo">
      <li>
        我们非常重视保护未成年人的个人信息。根据相关法律法规的规定，若您是18周岁以下的未成年人，建议您的父母或监护人仔细阅读本政策并在征得您的父母或监护人的同意后提交您的个人信息，同时建议您在您的父母或监护人的指导下使用我们的服务，否则请您务必立即终止提交信息或终止使用我们的服务，并尽快将此情况通知我们，以便我们采取有效的措施。同时，我们只会在受到法律法规允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或披露未成年人的信息。若您是未成年人的父母或监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第本隐私政策首页或十四条中的联系方式与我们联系。
      </li>
    </ul>
    <h4 style="margin: bottom 0.5rem;">九、您的个人信息如何在全球范围转移</h4>
    <ul class="ulTwo">
      <li>
        对于中国大陆居民的人个人信息，将存储在中华人民共和国境内。由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
      </li>
    </ul>
    <h4>十、我们可能向您发送的信息</h4>
    <ul class="ulFour">
      <p>
        1.您在使用我们的服务时，我们可能向您发送电子邮件、短信、资讯或推送通知。您可以按照我们的相关提示，在设备上选择取消订阅。
      </p>
      <p>
        2.我们可能在必要时(例如，因系统维护而暂停某一项服务时)向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于广告的公告。
      </p>
    </ul>
    <h4 style="margin: bottom 0.5rem;">十一、您分享的信息</h4>
    <ul class="ulTwo">
      <li>
        您可以通过我们的服务与您的好友、家人及其他用户分享您的相关信息，例如，您在香港红狮集团中公开分享的文字和照片。您可以通过我们服务中的隐私设置来控制您分享信息的范围，也可以通过服务中的设置或我们提供的指引删除您公开分享的信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地保存。
      </li>
    </ul>
    <h4 style="margin: bottom 0.5rem;">十二、本政策如何更新</h4>
    <ul class="ulTwo">
      <li>
        我们将根据相关法律法规的要求、业务发生变化或其他必要情形下，及时更新本隐私政策，未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利，我们会在本页面上发布对本政策所做的变更，并通过官网发布或其他您可能合适接收通知的方式告知修改后的政策。在该种情况下，若您继续使用我们的服务，即表示同意受经修订更新后的政策约束。
      </li>
    </ul>
    <h4>十三、本政策适用范围</h4>
    <ul class="ulFour">
      <p>
        1.除我们明确说明需适用我们其他特别制定的单独隐私政策或条款的服务外，本政策适用于您使用的所有香港红狮集团公司提供的全部服务。但某些服务已根据需要设定其特定的隐私指引/声明，如本政策与特定服务的隐私指引/声明有不一致之处，请以该特定隐私指引/声明为准。
      </p>
      <p>
        2.本政策所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本政策涵义解释的依据。
      </p>
    </ul>
    <h4 style="margin: bottom 0.5rem;">十四、如何联系我们</h4>
    <ul class="ulTwo">
      <li>
        如果您对本隐私政策或数据处理有任何疑问、意见或建议，可以通过红狮集团客服，或邮件联系。<br/>
        邮箱: cs@rlcvipltd.net<br/>
        联系人: 香港红狮集团有限公司<br/>
        地址: Flat F 10/F Comfort Building 88 Nathan Road Kowloon Hongkong
      </li>
    </ul>
    <p style="margin-top:30px;font-size:0.26rem;">本隐私政策条款，更新于2021年10月06日</p>
  </div>
</template>

<script>
export default {
  name:'Privacy',
  // created(){
  //   // 传参控制显示顶部和底部
  //   this.$emit('controlShow', false);
  // }
}
</script>

<style lang="less" scoped>
  .privacyBox{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5rem 0 1.1rem;
    h3{
      font-size: 0.3rem;
    }
    .line{
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      margin: 0.4rem auto 0.6rem;
    }
    .ulOne{
      margin: 0 auto;
      li{
        line-height: 0.38rem;
        letter-spacing: 1px;
        margin-bottom: 0.4rem;
        color: #6d6d6d;
        font-size: 0.26rem;
      }
    }
    .ulTwo{
      li{
        line-height: 0.38rem;
        letter-spacing: 1px;
        color: #6d6d6d;
        font-size: 0.26rem;
      }
    }
    h4{
      width: 100%;
      float: left;
      margin: 0.5rem 0 0;
      font-size: 0.26rem;
    }
    h5{
      font-size: 0.24rem;
    }
    .ulThree{
      line-height: 0.38rem;
      letter-spacing: 1px;
      color: #6d6d6d;
      font-size: 0.26rem;
      span{
        // color: #fe0100;
      }
    }
    .ulFour{
      width: 100%;
      margin-top: 0.4rem;
      p{
        line-height: 0.38rem;
        letter-spacing: 1px;
        color: #6d6d6d;
        margin: 0;
        font-size: 0.26rem;
      }
      li{
        padding-left: 0.2rem;
        line-height: 0.38rem;
        letter-spacing: 1px;
        color: #6d6d6d;
        font-size: 0.26rem;
      }
    }
  }
</style>